<template>
  <SimpleWidget
    icon="language"
    :items="items"
  />
</template>

<script setup>
    import { ref }      from 'vue';
    import { useStore } from 'boot/store';
    import SimpleWidget from 'components/widgets/simple/SimpleWidget';

    const store = useStore();

    const onClick = ( action ) => {
        store.dispatch('locales/setUserLocale', action);
        location.reload();
    };

    const items = ref([
        { action: 'ru', click: onClick, name: 'RUS', visible: true },
        { action: 'en', click: onClick, name: 'ENG', visible: true },
    ]);
</script>

<style scoped>

</style>