export default {
  "E-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Э-почта"])},
  "Not filled in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не заполнено"])},
  "Wrong value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не верно"])},
  "Rows per page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на страницу"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "Good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорошо"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "Valid after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно с"])},
  "Valid before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно до"])},
  "Or only Latin or only Russian characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или только латинские или только русские символы"])},
  "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка"])},
  "No data available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных"])},
  "No matched records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет подходящих записей"])},
  "date_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воскресение_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота"])},
  "date_days_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вс_Пн_Вт_Ср_Чт_Пт_Сб"])},
  "date_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь"])},
  "date_months_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Нбр_Дек"])},
  "date_plural_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней"])}
}