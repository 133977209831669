export default {
  "E-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "Not filled in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not filled in"])},
  "Wrong value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong value"])},
  "Rows per page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Valid after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid after"])},
  "Valid before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid before"])},
  "Or only Latin or only Russian characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or only Latin or only Russian characters"])},
  "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "No data available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
  "No matched records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matched records"])},
  "date_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воскресение_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота"])},
  "date_days_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вс_Пн_Вт_Ср_Чт_Пт_Сб"])},
  "date_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь"])},
  "date_months_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Нбр_Дек"])},
  "date_plural_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней"])}
}