<template>
  <q-btn-dropdown
    stretch
    flat
    split
    :icon="icon"
    @click="onClick"
  >
    <q-list
      class="bg-blue-grey-1 text-dark"
    >
      <SimpleWidgetItem
        v-for="(item, index) in items"
        v-show="item.visible"
        :key="`item-${index}`"
        :action="item.action"
        :click="item.click"
        :link="item.link"
        :name="item.name"
      />
    </q-list>
  </q-btn-dropdown>
</template>

<script setup>
    // import { useSettings }  from 'boot/settings';
    import { useRouter }    from 'vue-router';
    import SimpleWidgetItem from './SimpleWidgetItem';

    // const settings = useSettings();
    const router = useRouter();

    const props = defineProps({
        icon: {
            type: [ String, null ],
            default: null,
        },
        link: {
            type: [ String, Object ],
            default: '',
        },
        items: {
            type: [ Array, null ],
            default: null,
        },
    });

    const onClick = () => {
        router.push(props.link);
    };
</script>

<style scoped>

</style>