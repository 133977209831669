import date from 'quasar/src/utils/date.js';; // see https://quasar.dev/quasar-utils/date-utils

export function getDate( value, now = true ) {
    if (!now && !value) return null;
    let datetime = !!value ? Date.parse(value) : Date.now();
    return date.formatDate(datetime, 'DD.MM.YYYY');
}

export function getUTCDate( value, now = true ) {
    if (!now && !value) return null;
    // TODO !!!
    let datetime = !!value ? Date.parse(value) : Date.now();
    let dt = new Date(+datetime);
    let y = dt.getUTCFullYear().toString();
    let m = (dt.getUTCMonth() + 1).toString();
    let d = dt.getUTCDate().toString();
    return (d.length === 1 ? '0' : '') + d
           + '.' + (m.length === 1 ? '0' : '') + m
           + '.' + y;
}

export function getTime( value, now = true ) {
    if (!now && !value) return null;
    let datetime = !!value ? Date.parse(value) : Date.now();
    return date.formatDate(datetime, 'HH:mm:ss');
}

export function getUTCTime( value, now = true ) {
    if (!now && !value) return null;
    // TODO !!! ???
    let datetime = !!value ? Date.parse(value) : Date.now();
    let dt = new Date(+datetime);
    let h = dt.getUTCHours().toString();
    let m = dt.getUTCMinutes().toString();
    let s = dt.getUTCSeconds().toString();
    return (h.length === 1 ? '0' : '') + h
           + ':' + (m.length === 1 ? '0' : '') + m
           + ':' + (s.length === 1 ? '0' : '') + s;
}

export function getTimeZone( value, now = true ) {
    if (!now && !value) return null;
    let datetime = !!value ? Date.parse(value) : Date.now();
    return date.formatDate(datetime, 'Z');
}

export function getDateTime( value, now = true, tz = false ) {
    if (!now && !value) return null;
    let datetime = !!value ? Date.parse(value) : Date.now();
    return date.formatDate(datetime, 'DD.MM.YYYY HH:mm:ss' + (tz ? 'Z' : ''));
}

export function getUTCDateTime( value, now = true, tz = false ) {
    let date = getUTCDate(value, now);
    let time = getUTCTime(value, now);
    if (!date || !time) return null;
    return date + ' ' + time + (tz ? '+00:00' : '');
}

export function validateDateTimeTZ( value ) {
    return date.isValid(value);
}

export function convertFromStringToDateFormat( value ) {
    if (!value) return null;
    const data = value.split('.');
    return data[ 2 ] + '-' + data[ 1 ] + '-' + data[ 0 ];
}

export function convertFromStringToDate( value ) {
    if (!value) return null;
    const data = value.split('.');
    return new Date(+data[ 2 ], (+data[ 1 ] - 1), +data[ 0 ]);
}

export function validateDate( y, m, d ) {
    y = +y;
    --m;
    d = +d;
    let check = new Date(y, m, d);
    return check.getFullYear() === y && check.getMonth() === m && check.getDate() === d;
}

export function validateStringDate( value ) {
    const data = value.split('.');
    return validateDate(data[ 2 ], data[ 1 ], data[ 0 ]);
}

export function isTodayDate( date ) {
    let now = new Date();
    return date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate();
}

export function isTodayStringDate( value ) {
    let check = convertFromStringToDate(value);
    return isTodayDate(check);
}

export function implodeDateTimeTZ( d, t = null, tz = null ) {
    if (!d) return null;
    let datetime = convertFromStringToDateFormat(d);
    datetime += ' ' + (!!t ? t : '00:00:00');
    if (!!tz) {
        // let z = tz.replace(':', '');
        // TODO убрать последние 2 нуля
        datetime += tz.replace(':', '');
    }
    return datetime;
}

export function getLeadZeroDigit( val, num = 2 ) {
    let result = '' + val;
    let len = result.length;
    if (num > len) {
        let zero = '0';
        let add = num - len;
        result = zero.repeat(add) + result;
    }
    return result;
}

export function getModuleRoutes( rules ) {
    const result = [];
    rules.forEach(( item ) => {
        result.push({
            path: item.path,
            name: item.name,
            component: item.component,
            meta: item.meta,
        });
    });

    return result;
}

export function pushErrorsToField( fields, errors ) {
    for (let key in errors) {
        if (!Object.prototype.hasOwnProperty.call(errors, key))
            continue;
        let item = errors[ key ][ 0 ];
        fields.value.forEach(( field ) => {
            if (field.id === key) {
                field.error = item;
            }
        });
    }
}

export function findInArrayOfObject( items, key, value ) {
    items.forEach(( item ) => {
        if (item[ key ] === value) {
            return item;
        }
    });
    return null;
}

export function convertArrayToObject( items, key, value ) {
    let result = {};
    items.forEach(( item ) => {
        result[ item[ key ] ] = item[ value ];
    });
    return result;
}
