const path = '/talk';
const module = 'talk';
const talk = 'talk';

const rules = [
    // talk
    {
        path: path,
        name: module,
        component: () => import('external/talks/pages/MainPage'),
        meta: { layout: 'default', talk },
    },
    {
        path: path + '/:slug',
        name: module + '-section',
        component: () => import('external/talks/pages/SectionPage'),
        meta: { layout: 'default', talk, route: { 'topic': module + '-topic' } },
    },
    {
        path: path + '/:section/:slug',
        name: module + '-topic',
        component: () => import('external/talks/pages/TopicPage'),
        meta: { layout: 'default', talk },
    },

    // skeds // TODO наверное чат надо переделать в систем
    // {
    //     path: '/ham/' + skeds,
    //     name: skeds,
    //     component: () => import('external/talks/pages/MainPage'),
    //     meta: { layout: 'default', talk: skeds },
    // },
    {
        path: '/ham/chat/:slug',
        name: 'system-section',
        component: () => import('external/talks/pages/SectionPage'),
        meta: { layout: 'default', talk: 'system', route: { topic: 'system-topic' } },
    },
    {
        path: '/ham/chat/:section/:slug',
        name: 'system-topic',
        component: () => import('external/talks/pages/TopicPage'),
        meta: { layout: 'default', talk: 'system' },
    },
];

export default function getRules() {
    return rules;
}
