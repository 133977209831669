const path = '/ham';
const module = 'qsolog';

const rules = [
    {
        path: path,
        name: module + '-main',
        component: () => import('external/qsolog/pages/DashBoardPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/callsigns/search',
        name: module + '-callsigns',
        component: () => import('external/qsolog/pages/SearchCallSignPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/callsign/:callsign',
        name: module + '-callsign',
        component: () => import('external/qsolog/pages/CallSignProfilePage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/callsigns/my',
        name: module + '-callsigns-my',
        component: () => import('external/qsolog/pages/CallsignsPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/operators/:id',
        name: module + '-operators',
        component: () => import('external/qsolog/pages/OperatorsPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/log/my',
        name: module + '-log',
        component: () => import('external/qsolog/pages/LogPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/log/import',
        name: module + '-log-import',
        component: () => import('external/qsolog/pages/LogImportPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/log/statistics',
        name: module + '-log-statistics',
        component: () => import('external/qsolog/pages/StatisticsPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/diplomas/my',
        name: module + '-diplomas',
        component: () => import('external/qsolog/pages/DiplomasPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/diploma/:id',
        name: module + '-diploma',
        component: () => import('external/qsolog/pages/DiplomaPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/teams',
        name: module + '-teams',
        component: () => import('external/qsolog/pages/TeamsPage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/team/:slug',
        name: module + '-team',
        component: () => import('external/qsolog/pages/TeamProfilePage'),
        meta: { layout: 'default', auth: 'Y' },
    },
    {
        path: path + '/clubs',
        name: module + '-clubs',
        component: () => import('external/qsolog/pages/ClubsPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/club/:slug',
        name: module + '-club',
        component: () => import('external/qsolog/pages/ClubProfilePage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/events',
        name: module + '-events',
        component: () => import('external/qsolog/pages/EventsPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/events/:slug',
        name: module + '-event-group',
        component: () => import('external/qsolog/pages/EventGroupPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/event/:slug',
        name: module + '-event',
        component: () => import('external/qsolog/pages/EventProfilePage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/completion/:id',
        name: module + '-diploma-completion',
        component: () => import('external/qsolog/pages/DiplomaProgramCompletionPage'),
        meta: { layout: 'default' },
    },
    {
        path: path + '/settings',
        name: module + '-settings',
        component: () => import('external/qsolog/pages/SettingsPage'),
        meta: { layout: 'default' },
    },
];

export default function getRules() {
    return rules;
}
