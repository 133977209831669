import { Store }            from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS             from 'secure-ls';

import { errorsDataStore }   from 'components/errors/store';
import { sessionDataStore }  from 'modules/session/store';
import { localesDataStore }  from 'modules/locales/store';
import { userAuthDataStore } from 'modules/userauth/store';
import { externalDataStore } from 'external/store';


// https://github.com/softvar/secure-ls
let ls = new SecureLS({
    encodingType: (process.env.NODE_ENV !== 'production') ? '' : 'des',
    isCompression: false,
    encryptionSecret: process.env.VUE_APP_SECRET_KEY,
});

const store = new Store({
    strict: process.env.NODE_ENV !== 'production',

    modules: {
        errors: errorsDataStore,
        session: sessionDataStore,
        locales: localesDataStore,
        user: userAuthDataStore,
        external: externalDataStore,
    },
    plugins: [
        createPersistedState({
            // paths: [ 'data' ],
            storage: {
                getItem: ( key ) => ls.get(key),
                setItem: ( key, value ) => ls.set(key, value),
                removeItem: ( key ) => ls.remove(key),
            },
        }),
    ],
});

export function useStore() {
    return store;
}
