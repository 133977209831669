export const localesDataStore = {
    namespaced: true,
    state: {
        user_locale: null,
    },
    getters: {
        userLocale: ( state ) => {
            return state.user_locale;
        },
    },
    mutations: {
        setUserLocale( state, value ) {
            state.user_locale = value;
        },
    },
    actions: {
        setUserLocale( { commit }, value ) {
            commit('setUserLocale', value);
        },
    },
};